import { compose } from "recompose";
import { graphql } from "react-apollo";

const EnhanceWidgetBlock = ({ widgetBlockQuery, WidgetBlockQueryById }) =>
  compose(
    graphql(widgetBlockQuery, {
      skip: ({ attributes }) => !attributes.block_identifier,
      name: "widgetBlock",
      options: ({ attributes: { block_identifier } }) => ({
        variables: {
          identifiers: [block_identifier],
        },
      }),
      props: ({ widgetBlock, ownProps : { attributes: { block_identifier } } }) => ({
        widgetBlock: {
          loading: widgetBlock.loading,
          allData: widgetBlock,
          data:
            !widgetBlock.loading &&
            widgetBlock.cmsBlock &&
            widgetBlock.cmsBlock.find(
              (block) => block && block.identifier === block_identifier
            ),
        },
      }),
    }),
    graphql(WidgetBlockQueryById, {
      skip: ({ attributes }) => !attributes.block_id,
      name: "widgetBlock",
      options: ({ attributes: { block_id }}) => ({
        variables: {
          ids: [block_id],
        },
      }),
      props: ({ widgetBlock, ownProps: { attributes: { block_id }}}) => ({
        widgetBlock: {
          loading: widgetBlock.loading,
          allDataById: widgetBlock,
          data:
            !widgetBlock.loading &&
            widgetBlock.cmsBlock &&
            widgetBlock.cmsBlock.find(
              (block) => block && block.id === block_id
            )
        },
      }),
    }),
  );

export default EnhanceWidgetBlock;
