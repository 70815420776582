import React from "react";
import EnhanceWidgetBlock from "./EnhanceWidgetBlock";
import widgetBlockQuery from "./WidgetBlockQuery.gql";
import WidgetBlockQueryById from "./WidgetBlockQueryById.gql"
import Wysiwyg from "theme/modules/WysiwygV2";

const WidgetBlock = ({ widgetBlock }) => {
  return widgetBlock && widgetBlock.data?.content ? (
    <Wysiwyg
      containerClass="cms-block"
      content={widgetBlock.data.contentWysiwyg}
      heroEffect={widgetBlock.data?.kpl_hero_banner_effect}
    />
  ) : null;
};

export default EnhanceWidgetBlock({
  widgetBlockQuery,
  WidgetBlockQueryById
})(WidgetBlock);
